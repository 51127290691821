import uniqid from 'uniqid';
import { skills } from '../../portfolio';
import './Skills.css';

const Skills = () => {
    if (!skills.length) return null;

    return (
        <section className='section skills' id='skills'>
            <h2 className='section__title'>Skills</h2>
            <div className='skills__container'>
                {skills.map((skill) => (
                    <div key={uniqid()} className='skill__tile'>
                        <h3 className='skill__title'>{skill.name}</h3>
                        <p className='skill__description'>{skill.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Skills;

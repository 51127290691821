import './Footer.css'
import GitHubIcon from "@material-ui/icons/GitHub";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const Footer = () => (
  <footer className='footer'>
      <h3>FOLLOW</h3>
    <a
      href='https://github.com/migueldb1'
      className='link footer__link'
      target="_blank" rel="noreferrer"
    >
        <GitHubIcon />
    </a>
      <a
          href='https://www.linkedin.com/in/miguel-de-backer-998245206/'
          className='link footer__link'
          target="_blank" rel="noreferrer"
      >
          <LinkedInIcon />
      </a>
      <br />
      <br />
      <br />
      Miguel &copy;	{new Date().getFullYear()}
  </footer>
)

export default Footer

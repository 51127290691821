import React, { useEffect  } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';


const BlogPost5 = () => {
    // Scroll to the top of the page when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">My Journey in Building and Managing this website</h1>
            <p className="intro">In the ever-evolving world of cybersecurity, having a dedicated platform to share insights and experiences is invaluable. This is the story of how I created my website, a project that not only served as a practical application of my skills but also as a continual learning experience.</p>

            <h2>Choosing the Framework: React</h2>
            <p>I settled on using the React framework. React's component-based architecture made it an ideal choice for a dynamic blog like mine, where content needed to be updated and managed efficiently. Its robust ecosystem and active community support were crucial in handling various challenges during development. The decision to use React was driven by its scalability and the need for a responsive, interactive user experience.</p>

            <h2>Hosting: Navigating the World of VPS</h2>
            <p>For hosting, I chose a Virtual Private Server (VPS). The VPS provided me with the flexibility to manage the server environment according to the specific needs of my blog. This approach required a good understanding of server management and security practices, critical in the cybersecurity field. I learned to configure and secure the webserver, ensuring that the blog runs smoothly and remains resilient against potential threats.</p>

            <h2>Leveraging Cloudflare for DNS and Security</h2>
            <p>Integrating Cloudflare as a DNS proxy was a strategic move to enhance the security and performance of the blog. Cloudflare not only helped in managing DNS records with ease but also provided an additional layer of security against DDoS attacks, a common threat in the cybersecurity domain. Its CDN services also improved the loading time of the blog, a crucial factor for user experience.</p>

            <h2>The Effort Behind Adding a New Post</h2>
            <p>Adding new content to the blog is a process that is straightforward. It involves adding a new post by updating components in React. The modular nature of React components streamlines this process, but it still requires a careful update to ensure consistency and responsiveness across the blog. Once the post is drafted and integrated, I test the website locally before I deploy it on my webserver.</p>

            <h2>Reflection and Future Directions</h2>
            <p>Looking back, I realize that managing this blog has been helpful towards my technical development. It also challenges me to stay updated in my field and to share my journey in a way that resonates with others.</p>

            <h2>Conclusion</h2>
            <p>Creating and maintaining this website has been a journey marked by both challenges and triumphs. Through each step of developing the site with React, hosting it on a VPS, and integrating Cloudflare for security and efficiency, I've gained invaluable insights into the practical aspects of cybersecurity and web development. The effort involved in updating the blog with new posts.</p>


            <div className="video-container">
                <img src= "/img/blog/making-website.png" alt="making-website" />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/advent-of-cyber-2023">
                    <ArrowBack className="icon-left" />
                    CTF: Advent of Cyber
                </a>
                <a className="a-blog" href="/blog/404-game">
                    404 game
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost5;

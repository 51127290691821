// CatchTheEmoji.js
import React, { useState, useEffect } from 'react';
import Emoji from './Emoji';
import './NotFound.css';

function getRandomPosition() {
    const x = Math.floor(Math.random() * (window.innerWidth - 100));
    const y = Math.floor(Math.random() * (window.innerHeight - 100));
    return { x, y };
}

function CatchTheEmoji() {
    const emojis = ['🐌', '👀', '🐢', '💀'];
    const [position, setPosition] = useState(getRandomPosition());
    const [score, setScore] = useState(0);
    const [currentEmoji, setCurrentEmoji] = useState(emojis[0]); // Start with the first emoji

    useEffect(() => {
        const intervalId = setInterval(() => {
            setPosition(getRandomPosition());
        }, 750);

        return () => clearInterval(intervalId);
    }, []);

    const handleClick = () => {
        const newScore = score + 1;
        setScore(newScore);
        setPosition(getRandomPosition());

        // Check if the score is a multiple of 2 to change the emoji
        if (newScore % 2 === 0) {
            const nextEmojiIndex = (newScore / 2) % emojis.length;
            setCurrentEmoji(emojis[nextEmojiIndex]);
        }

        if (newScore === 5) {
            // eslint-disable-next-line no-alert
            window.alert('You reached this far, try to get to 10!');
            document.body.classList.add('shake');
        }

        if (newScore === 10) {
            // eslint-disable-next-line no-alert
            window.alert('Nothing better to do? 💀');
            window.location.href = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';
        }
    };

    const emojiStyle = {
        position: 'fixed', // 'fixed' or 'absolute' depending on your need
        left: `${position.x}px`,
        top: `${position.y}px`,
        transition: 'left 0.5s, top 0.5s', // Smooth transition for the movement
        fontSize: '40px',
        cursor: 'pointer',
        userSelect: 'none'
    };

    return (
        <div>
            <h2>Score: {score}</h2>
            <Emoji
                emoji={currentEmoji}
                onClick={handleClick}
                style={emojiStyle}
            />
        </div>
    );
}

export default CatchTheEmoji;

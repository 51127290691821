import React, { useEffect  } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';


const BlogPost3 = () => {
    // Scroll to the top of the page when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">Study Visit: Insights from ConXioN on Zero Trust Networks</h1>
            <h2>Embracing a Zero Trust Approach in Cybersecurity</h2>
            <p className="intro">Recently, I had the opportunity to visit ConXioN, a leading technology company and a first-tier partner with Microsoft. The highlight of my visit was a lecture on their approach to cybersecurity, particularly focusing on Zero Trust networks. This concept is pivotal in today's digital landscape where traditional security perimeters are no longer sufficient.</p>

            <h2>The Core of Zero Trust</h2>
            <p>Zero Trust operates on a fundamental principle: "Never trust, always verify." Unlike conventional security models that trust everything inside the corporate network, Zero Trust assumes that threats can exist both inside and outside the network. It's about verifying each access request as if it originates from an untrusted network. This principle is critical in today's environment, characterized by remote work and cloud-based services.</p>

            <h2>Key Takeaways from Microsoft's Zero Trust Model</h2>
            <ul>
                <li><strong>Verify Explicitly:</strong> Every access request should be authenticated and authorized based on multiple data points like user identity, device health, location, and more.</li>
                <li><strong>Least-Privilege Access:</strong> Implement just-in-time and just-enough access, minimizing user access to what's strictly necessary for their role.</li>
                <li><strong>Assume Breach:</strong> Adopt strategies that minimize the impact of potential breaches and improve threat detection and response mechanisms.</li>
                <li><strong>Telemetry and Analytics:</strong> Utilizing rich intelligence and analytics to respond to anomalies in real-time is crucial for this model.</li>
            </ul>

            <div className="con-scheme">
                <img src= "/img/blog/concion-scheme.png" alt="conxion-ztn-scheme" />
            </div>


            <h2>ConXioN's Implementation and My Observations</h2>
            <p>ConXioN, in its partnership with Microsoft, leverages these Zero Trust principles effectively. They emphasize layered security, proactive management, and monitoring to safeguard against various cyber threats. Their approach resonates with the Zero Trust model's fundamentals.</p>

            <h2>Conclusion</h2>
            <p>My visit to ConXioN was an eye-opener to the importance of adopting a Zero Trust approach in today's cybersecurity strategies. As cyber threats evolve, it's crucial that our defense mechanisms evolve too. Zero Trust is not just a security model; it's a necessary paradigm shift in how we think about and implement cybersecurity.</p>

            <div className="video-container">
                <img src= "/img/blog/conxion.jpg" alt="conxion-sec-tour-floor" />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/webinar-zero-trust">
                    <ArrowBack className="icon-left" />
                    Webinar - Zero Trust
                </a>
                <a className="a-blog" href="/blog/advent-of-cyber-2023">
                    CTF: Advent of Cyber
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost3;

import React, { useEffect  } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';


const BlogPost6 = () => {
    // Scroll to the top of the page when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">Transforming 404 Frustration into Fun: <br /> The Birth of 'Catch the Emoji'</h1>
            <p className="intro">In the digital world, encountering a 404 error page can often be a frustrating experience. However, in my continuous journey to blend creativity with technology, I decided to transform this common annoyance into an opportunity for a bit of fun. This led to the creation of 'Catch the Emoji', a simple yet engaging game that appears on the 404 pages of my website.</p>

            <h2>The Game Mechanics</h2>
            <p>The game is built using the React framework, capitalizing on its component-based architecture for smooth gameplay. Here's a glimpse into the code and logic behind 'Catch the Emoji':</p>

            <ul className="list-game">
                <li><b>React:</b> The use of React allows for a seamless integration of the game into the website's architecture.</li>
                <li><b>CSS Transitions:</b> The smooth movement of emojis across the screen is achieved using CSS transitions, providing a fluid visual experience.</li>
                <li><b>Accessibility:</b> Accessibility is also considered, with keyboard navigability implemented in the Emoji component.</li>
                <li><b>Random Positioning:</b> The game randomly positions emojis across the screen, ensuring a dynamic and unpredictable experience.</li>
                <li><b>Scoring System:</b> Players score points by clicking on the emojis, with the score displayed prominently.</li>
                <li><b>Emoji Evolution:</b> As the player's score increases, the emoji changes, adding a layer of visual interest and achievement.</li>
                <li><b>Milestones and Rewards:</b> Reaching certain scores triggers humorous alerts and, eventually, redirects the player to a surprise link, adding an element of unexpected fun</li>
            </ul>

            <h2>User Experience at the Forefront</h2>
            <p>What sets 'Catch the Emoji' apart is its focus on enhancing the user experience. Instead of a standard error message, visitors are greeted with a playful challenge. It's a small touch, but it reflects my philosophy of infusing fun and interactivity into web design.</p>

            <h2>A Reflection of My Learning Journey</h2>
            <p>Creating 'Catch the Emoji' was not just about building a game; it was a practical application of my skills in web development and a reflection of my learning journey in cybersecurity and programming. It demonstrates how technical knowledge can be used creatively to enhance the user experience.</p>

            <h2>Try it out yourself</h2>
            <p>For those interested in trying out 'Catch the Emoji' or learning more about its development, I invite you to visit a 404 page on my website. Who knows, you might just find yourself striving to beat your high score instead of feeling lost on the internet!</p>
            <a className="game" href="https://migueldb.be/CanBeAnyNonExistingPage" target="_blank" rel="noreferrer"> Click here to play the 404 game </a>


            <div className="video-container">
                <img src= "/img/blog/404-game.png" alt="404-game" />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/the-making-of-this-website">
                    <ArrowBack className="icon-left" />
                    Making this site
                </a>
                <a className="a-blog" href="/blog/cscbe-ctf">
                    cscbe CTF
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost6;

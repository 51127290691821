import React, { useEffect } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import './BlogPost.css';

const BlogPost1 = () => {
    // Scroll to the top of the page when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            <h1 className="blog-title">An Unforgettable Experience at the Campus Cloud Café, Howest, Kortrijk</h1>
            <p className="intro">What an amazing time I had at the Campus Cloud Café held at Howest, Kortrijk! The event kicked off at 5:00 PM, promising an enriching day filled with valuable insights and opportunities to expand our professional connections.</p>

            <h2>Session 1: <br/> Real-life Use Cases with Azure OpenAI by Mathias Cheyns</h2>
            <p>The first session was truly enlightening. He took us on a journey through the practical applications of Azure OpenAI across various industries, showcasing how these advanced AI tools can effectively address real-world challenges. As a developer or IT professional, I found myself truly inspired by the limitless possibilities that artificial intelligence offers.</p>

            <h2>Session 2: <br/> Lessons Learned in Building an Industrial IoT Platform on Azure by Toon Vanhoutte</h2>
            <p>Session two provided a deeper understanding of the intricacies, challenges, and opportunities within the realm of the Internet of Things (IoT). Toon his wealth of experience undoubtedly imparted valuable insights into constructing industrial IoT platforms on Azure.</p>

            <h2>Intermission</h2>
            <p>Following this insightful session, we enjoyed a well-deserved intermission. An array of delectable sandwiches was on hand for all attendees to relish, creating the perfect atmosphere for networking and engaging in meaningful conversations with fellow participants.</p>

            <h2>Session 3: <br/> Azure Durable Functions 101 by Klaas Lauwers</h2>
            <p>In the third session we delved into the art of crafting scalable and dependable serverless applications using Azure Durable Functions. This knowledge is of immense value to developers and architects in search of efficient solutions for their applications.</p>
            <br/>
            <p>All in all, my experience at the Campus Cloud Café, hosted at Howest in Kortrijk, was nothing short of unforgettable and enlightening.</p>


            <div className="blog-nav">
                <a className="a-blog" href="/blog/webinar-zero-trust">
                    Webinar Zero Trust
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost1;

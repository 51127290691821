import React from 'react';
import uniqid from 'uniqid';
import { blogs } from '../../portfolio';
import BlogPostContainer from '../BlogContainer/BlogContainer';
import './Blog.css';

const Blogs = () => {
    if (!blogs.length) return null;

    return (
        <section id='blogs' className='section blogs'>
            <h2 className='section__title'>Blog Posts</h2>

            <div className='blogs__grid'>
                {blogs.map((blog) => (
                    <BlogPostContainer key={uniqid()} blog={blog} />
                ))}
            </div>
        </section>
    );
};

export default Blogs;

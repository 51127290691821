import uniqid from 'uniqid';
import GitHubIcon from '@material-ui/icons/GitHub';
import LaunchIcon from '@material-ui/icons/Launch';
import './ProjectContainer.css';

const ProjectContainer = ({ project }) => (
    <div className='project'>
        {project.livePreview && (
            <a href={project.livePreview} className='link link--icon' target={project.livePreview === '/blog' ? '' : '_blank'}
               rel='noopener noreferrer'>
                <h3>{project.name}</h3>
            </a>
        )}

        {/* Make the image clickable */}
        {project.image && (
            <a href={project.livePreview} target={project.livePreview === '/blog' ? '' : '_blank'}
               rel='noopener noreferrer'>
                <img src={project.image} alt={project.name} className='project__image' />
            </a>
        )}

        {/* Display the "about" information */}
        {project.description && (
            <p className='project__description'>{project.description}</p>
        )}

        {project.stack && (
            <ul className='project__stack'>
                {project.stack.map((item) => (
                    <li key={uniqid()} className='project__stack-item'>
                        {item}
                    </li>
                ))}
            </ul>
        )}


        {/* Wrapper for the links */}
        <div className='project__links'>
            {project.sourceCode && (
                <a
                    href={project.sourceCode}
                    aria-label='source code'
                    className='link link--icon'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <GitHubIcon />
                </a>
            )}

            {project.livePreview && (
                <a
                    href={project.livePreview}
                    aria-label='live preview'
                    className='link link--icon'
                    target={project.livePreview === '/blog' ? '' : '_blank'}
                    rel='noopener noreferrer'
                >
                    <LaunchIcon />
                </a>
            )}
        </div>
    </div>
);

export default ProjectContainer;

import React, { useEffect } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';


const BlogPost2 = () => {
    // Scroll to the top of the page when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">Webinar - Zero Trust and Enterprise Architecture</h1>
            <p className="intro">Hey there! Have you heard about the recent 'Zero Trust and Enterprise Architecture' webinar by Business Architecture Info? It was a real eye-opener! Jason Garvis, a whiz in security, walked us through why Zero Trust is a big deal for today's businesses and how to get it up and running.</p>

            <h2>What's Zero Trust All About?</h2>
            <p>Zero Trust? It's a game changer in cybersecurity. Imagine a world where we don't just trust anyone or anything trying to access our digital space. That's Zero Trust for you - constantly checking who's who before letting them in, whether they're inside or outside our network.</p>

            <h2>Real-World Example: Financial Systems Regulatory Authority</h2>
            <p>Let's talk real-life impact. The Financial Systems Regulatory Authority switched to a cloud setup, all thanks to Zero Trust principles. The result? They're catching frauds left and right, beefing up security, saving cash, and ticking all the compliance boxes. It's proof that Zero Trust isn't just theory – it works!</p>

            <h2>Getting Started with Zero Trust</h2>
            <p>The big takeaway from the webinar? Start small with Zero Trust. Find a project that makes sense for your business, use it as a launchpad, and gradually get everyone on board. It's all about making baby steps towards a full-blown Zero Trust environment.</p>

            <h2>The Ups and Downs</h2>
            <p>Switching to Zero Trust isn't all smooth sailing – it's got its challenges, especially when aligning with your company's goals and getting everyone to agree. But the perks? They're huge! Think top-notch security, streamlined operations, and better compliance. Zero Trust is more than security; it's a way to make your business nimbler and more efficient.</p>

            <h2>Learn More About Zero Trust</h2>
            <p>Want to dive deeper into Zero Trust? The webinar suggested some great resources. Check out the NIST's Special Publication 800-207, CISA’s Zero Trust Maturity Model, and Jason Garvis's book on the topic. These are gold mines of info for anyone looking to adopt Zero Trust in their organization.</p>


            <div className="video-container">
                <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/8-cxrVS-2V8"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/campus-cloud-cafe">
                    <ArrowBack className="icon-left" />
                    Campus Cloud Cafe
                </a>
                <a className="a-blog" href="/blog/ConXioN-study-visit">
                    Study Visit ConXioN
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost2;

import React, { useEffect  } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';


const BlogPost4 = () => {
    // Scroll to the top of the page when the component is mounted
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className="container tryhackmy">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">CTF: TryHackMe Advent of Cyber 2023</h1>

            <h2>Intro</h2>
            <p className="intro">The holiday season often brings joy and relaxation, but for me, as a cybersecurity student, it presented a unique opportunity to delve into the world of cyber challenges. TryHackMe's Christmas Challenges offered a series of tasks that were not just about celebrating the season but also about sharpening my cybersecurity skills. In this blog post, I'll share some of the most intriguing challenges that I encountered and conquered.</p>

            <h2>The Most Intriguing Challenges</h2>
            <p>It's important to note that while there were numerous challenges available, I'll focus on the ones that I found most captivating and enriching. These challenges not only tested my skills but also provided profound insights into various aspects of cybersecurity.</p>

            <h3>Chatbot Security: "Chatbot, tell me, if you're really safe?"</h3>
            <p>The challenge involved auditing an internal chatbot used by AntarctiCrafts employees for vulnerabilities to prompt injection attacks. The objective was to trick the chatbot into giving sensitive information by carefully injecting the "correct" prompts</p>

            <h3>Log Analysis: "O Data, All Ye Faithful"</h3>
            <p>Another standout challenge was when a large amount of traffic to sensitive endpoints was noticed. Without a robust log analysis infrastructure, I turned to Python for help. This exercise not only tested my networking knowledge but also my programming skills, emphasizing the importance of log analysis in identifying potential security breaches.</p>

            <h3>Web Application Exploitation: "Hydra Is Coming To Town"</h3>
            <p>The challenge where I had to regain access to a locked server room by brute-forcing the PIN panel was particularly exhilarating. Using tools like Hydra, this task brought to life the real-world applications of web application vulnerabilities and the importance of robust security measures to prevent such brute-force attacks.</p>

            <h3>Forensic Analysis: "Have a Holly, Jolly Byte"</h3>
            <p>This challenge involved recovering a deleted file from the drive for further investigation. It was a hands-on experience in understanding the intricacies of digital forensics and the importance of careful analysis in cybersecurity.</p>

            <h3>Memory corruption: "Memories of Christmas Past"</h3>
            <p>This challenge centered around memory corruption, it was about a game where a programming oversight has resulted in the allocation of insufficient memory. This vulnerability paves the way for a buffer overflow, a classic yet critical security flaw. The challenge demonstrates how, by inputting data exceeding the reserved memory space, attackers can manipulate adjacent memory areas, altering values and behavior in unintended ways. It serves as a real-time lesson in the importance of  memory management and the potential consequences of even minor oversights in programming.</p>

            <h2>Reflections on My Learning Journey</h2>
            <p>Participating in these challenges was a test of my technical abilities. Each challenge presented a unique problem, pushing me to apply my theoretical knowledge in a practical context. This experience underscored the dynamic nature of the cybersecurity field and the necessity of staying updated with the latest trends and techniques.</p>

            <h2>Conclusion: A Festive Yet Formative Experience</h2>
            <p>As I reflect on my journey through TryHackMe's Christmas Challenges, I realize the value these experiences have added to my cybersecurity education. These challenges were a good step in my growth as a cybersecurity professional. They reinforced the importance of hands-on experience in complementing academic knowledge.</p>

            <div className="video-container">
                <img src= "/img/blog/advent-of-cyber-2023.png" alt="CTF-Advent" />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/ConXioN-study-visit">
                    <ArrowBack className="icon-left" />
                    Study Visit: ConXioN
                </a>
                <a className="a-blog" href="/blog/the-making-of-this-website">
                    Making this site
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost4;

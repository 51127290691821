import {Link, useLocation} from 'react-router-dom';
import { useContext, useState, useEffect } from 'react'
import Brightness2Icon from '@material-ui/icons/Brightness2'
import WbSunnyRoundedIcon from '@material-ui/icons/WbSunnyRounded'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeContext } from '../../contexts/theme'
import { projects, skills, contact } from '../../portfolio'
import './Navbar.css'

const Navbar = () => {
    const { themeName, toggleTheme } = useContext(ThemeContext)
    const [showNavList, setShowNavList] = useState(false)
    const location = useLocation();
    const toggleNavList = () => setShowNavList(!showNavList)

    useEffect(() => {
        if (showNavList) {
            document.body.classList.add('body-no-scroll');
        } else {
            document.body.classList.remove('body-no-scroll');
        }
    }, [showNavList]);

  return (
    <nav className='center nav'>
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >
          {location.pathname.startsWith('/blog') && (
              <li className='nav__list-item'>
                  <Link
                      to='/#about'
                      onClick={toggleNavList}
                      className='link link--nav'
                  >
                      About
                  </Link>
              </li>
          )}
        {projects.length ? (
          <li className='nav__list-item'>
            <a
              href='/#projects'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Projects
            </a>
          </li>
        ) : null}

        {skills.length ? (
          <li className='nav__list-item'>
            <a
              href='/#skills'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Skills
            </a>
          </li>
        ) : null}

        {contact.email ? (
          <li className='nav__list-item'>
            <a
              href='/#contact'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Contact
            </a>
          </li>
        ) : null}

          <li className='nav__list-item'>
              <Link
                  to='/blog'
                  onClick={toggleNavList}
                  className='link link--nav'
              >
                  Blog
              </Link>
          </li>


      </ul>

      <button
        type='button'
        onClick={toggleTheme}
        className='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {themeName === 'dark' ? <WbSunnyRoundedIcon /> : <Brightness2Icon />}
      </button>

      <button
        type='button'
        onClick={toggleNavList}
        className='btn btn--icon nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  )
}

export default Navbar

const header = {
  homepage: 'https://migueldb.be',
  title: 'MDB.',
}


const about = {
  name: 'Miguel De Backer',
  role: 'Student Cyber Security Professional',
  description:
      'I\'m in my third year Applied Computer Sciences at Howest, Bruges.' +
      '<br />' +
      'I\'m specializing in Cybersecurity.',
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/miguel-de-backer-998245206/',
    github: 'https://github.com/migueldb1',
  },
}

const projects = [
  {
    name: 'Honeypot',
    stack: ['Java', 'Vert.x' ,'PHP', 'Javascript', 'MySQL'],
    description: "Its goal is to manage users and allow them to submit flags to a CTF challenge. You can find the front-end, back-end and the challenges in the repository",
    sourceCode: 'https://github.com/orgs/honeypot-project/repositories',
    livePreview: 'https://github.com/orgs/honeypot-project/repositories',
    image: `${process.env.PUBLIC_URL  }/img/honeypot.jpg`,
  },
  {
    name: 'Android Spyware App',
    stack: ['Kotlin', 'SQLite'],
    description: "This application was a school project build using Kotlin. The goal is to create a normal looking app and track users location and dump contacts to a database without their knowledge.",
    sourceCode: 'https://github.com/migueldb1/Weather-Spyware-apk',
    livePreview: 'https://github.com/migueldb1/Weather-Spyware-apk',
    image: `${process.env.PUBLIC_URL  }/img/Android-Malware.jpeg`,
  },
  {
    name: 'UCDP: Proxy Server',
    stack: ['MySQL', 'HAProxy', 'Roxy-WI', 'apache2'],
    description: "This is a use case driven paper we wrote for Howest University of Applied Sciences. The paper is about 'Enabling Secure Access to Local Services: Proxy-Based Solutions for Overcoming NAT and Firewall Restrictions'",
    sourceCode: 'https://github.com/migueldb1/Enabling-Secure-Access-to-Local-Services',
    livePreview: 'https://github.com/migueldb1/Enabling-Secure-Access-to-Local-Services',
    image: `${process.env.PUBLIC_URL  }/img/proxy-server.png`,
  },
  {
    name: 'Personal Blog',
    stack: ['ReactJS', 'CSS'],
    description: "A collection of blog posts I wrote about my experiences in the Cyber Security field.",
    livePreview: '/blog',
    image: `${process.env.PUBLIC_URL  }/img/blog/blog.jpg`,
  }
]

const skills = [
  {
    name: 'Digital Forensics',
    description: 'Investigating digital data, analyzing Windows and Linux file systems, metadata, journals, anti-forensics and data recovery techniques'
  },
  {
    name: 'Network and System Pentesting',
    description: 'Performing in-depth penetration tests on networks and systems, using various tools and techniques'
  },
  {
    name: 'Web Server Management and Deployment',
    description: 'Configuring and managing web servers (Apache2, Nginx), handling a VPS, deploying websites'
  },
  {
    name: 'Network Security',
    description: 'TCP/IP, OSI Model, Firewalls, IDS, VPN, AV, ELK-stack, Proxy'
  },
  {
    name: 'Advanced Security Techniques',
    description: 'Reverse Engineering, Anti-forensics, Encryption'
  },
  {
    name: 'Web Development',
    description: 'HTML, CSS, Javascript, ReactJS, PHP, Laravel, Vert.x, SQL'
  },
  {
    name: 'Programming Languages',
    description: 'Java, Python, Kotlin, .NET, Javascript'
  },
  {
    name: 'Virtualization & Containerization',
    description: 'VMWare, Hyper-V, ESXi, KVM, LXC, Docker, Kubernetes'
  },
  {
    name: 'Server Administration',
    description: 'Windows Server, Linux Server'
  },
  {
    name: 'Scripting & Automation',
    description: 'Bash, Powershell'
  },
  {
    name: 'Web Pentesting',
    description: 'OWASP Top 10'
  },
  {
    name: 'Version Control',
    description: 'Github, Gitlab'
  },
];



const contact = {
  email: 'contact@migueldb.be',
}

const blogs = [
  {
    title: 'Campus Cloud Café',
    description: 'Campus Cloud Café sets up in Howest in Kortrijk with three interesting sessions about Microsoft Azure.',
    source: '/blog/campus-cloud-cafe',
    image: '/img/blog/campus-cloud-cafe.png',
  },
  {
    title: 'Webinar - Zero Trust and Enterprise Architecture',
    description:(
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Discussion of the webinar "Zero Trust and Enterprise Architecture",<br />
          highlighting the importance of integrating the Zero Trust security model into modern enterprise architecture.
        </div>
    ),
    source: '/blog/webinar-zero-trust',
    image: '/img/blog/zero-trust.png',
  },
  {
    title: 'Study Visit - Insights from ConXioN on Zero Trust Networks',
    description: (
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          I had the opportunity to visit ConXioN, a leading technology company and a first-tier partner with Microsoft.<br />
          The highlight of my visit was a lecture on their approach to cybersecurity, particularly focusing on Zero Trust networks.
        </div>
    ),
    source: '/blog/ConXioN-study-visit',
    image: '/img/blog/con-building.jpg',
  },
  {
    title: 'CTF: TryHackMe Advent of Cyber 2023',
    description: (
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          In this blogpost we dive into TryHackMe's Advent of Cyber Capture The Flag (CTF) challenges! <br />
          From encryption and web exploitation to network defense and forensic analysis. <br />
          Let us dive in these cybersecurity challenges and try to crack these one by one!
        </div>
    ),
    source: '/blog/advent-of-cyber-2023',
    image: '/img/blog/tryhackme-wallpaper-1.png',
  },
  {
    title: 'My Journey in Building and Managing this website',
    description: (
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          In the ever-evolving world of cybersecurity, having a dedicated platform to share insights and experiences is invaluable. <br />
          This is the story of how I created my website, a project that  served as a practical application of my web development skills.
        </div>
    ),
    source: '/blog/the-making-of-this-website',
    image: '/img/blog/making-website2.png',
  },
  {
    title: 'Transforming 404 Frustration into Fun: The Birth of \'Catch the Emoji\'',
    description: (
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The idea struck me one day after stumbling upon several dull 404 pages. <br />
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Why not use this space to offer visitors a small diversion? And thus, 'Catch the Emoji' was born.
        </div>
    ),
    source: '/blog/404-game',
    image: '/img/blog/404.png',
  },
  {
    title: 'CSCBE\'s CTF Challenge',
    description: (
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          The Cyber Security Challenge Belgium (CSCBE) is an annual CTF (Capture The Flag) competition <br />
          designed for students passionate about cybersecurity.
        </div>
    ),
    source: '/blog/cscbe-ctf',
    image: '/img/blog/cscbe.png',
  },
  {
    title: 'Our Lecture to High School Students',
    description: (
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          We recently had the privilege of conducting a comprehensive lecture on cybersecurity for high school students.
        </div>
    ),
    source: '/blog/cybersecurity-lecture',
    image: '/img/blog/lecture.png',
  },

];


export { header, about, projects, skills, contact, blogs }

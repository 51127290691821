import React, { useEffect, useState } from 'react';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { about } from '../../portfolio';
import './About.css';

const About = () => {
    const { name, role, description, social } = about;
    const [typedText, setTypedText] = useState('');
    const [cursorVisible, setCursorVisible] = useState(true);

    useEffect(() => {
        const textToType = 'Student Cyber Security Professional';
        let currentIndex = 0;

        const typingInterval = setInterval(() => {
            setTypedText((prevText) => prevText + textToType[currentIndex]);
            currentIndex += 1;

            if (currentIndex === textToType.length) {
                clearInterval(typingInterval);
                setCursorVisible(false);
            }
        }, 100);

        return () => {
            clearInterval(typingInterval);
        };
    }, []);

    useEffect(() => {
        const cursorInterval = setInterval(() => {
            setCursorVisible((prevVisible) => !prevVisible);
        }, 500);

        return () => {
            clearInterval(cursorInterval);
        };
    }, []);

    return (
        <div className="about center center-text">
            <img src={`${process.env.PUBLIC_URL}/img/miguel.png`} alt="Profile Miguel" className="center-text" />
            {name && (
                <h1>
                    Hi, I am <span className="about__name">{name}.</span>
                </h1>
            )}
            {role && (
                <h2 className="about__role center-text">
                    {typedText}
                    <span className={`about__cursor ${cursorVisible ? 'blink' : ''}`}>|</span>
                </h2>
            )}
            {/* eslint-disable-next-line react/no-danger */}
            <p className="about__desc center-text" dangerouslySetInnerHTML={{ __html: description }} />
            <div className="about__contact center center-text">
                {social && (
                    <>
                        {social.github && (
                            <a href={social.github} aria-label="github" className="link link--icon center-text" target="_blank" rel="noreferrer">
                                <GitHubIcon />
                            </a>
                        )}
                        {social.linkedin && (
                            <a href={social.linkedin} aria-label="linkedin" className="link link--icon center-text" target="_blank" rel="noreferrer">
                                <LinkedInIcon />
                            </a>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default About;

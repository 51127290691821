// Emoji.js
import React from 'react';

function Emoji({ emoji, onClick, style }) {
    // Function to handle keyboard 'Enter' as a click
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === ' ') {
            onClick();
        }
    };

    return (
        <div
            role="button"
            tabIndex={0}
            onClick={onClick}
            onKeyPress={handleKeyPress}
            style={style}
            aria-label="Catch the emoji"
        >
            {emoji}
        </div>
    );
}

export default Emoji;

import React from 'react';
import './NotFound.css';
import CatchTheEmoji from './CatchTheEmoji';

const NotFound = () => (
        <div className="not-found-container">
            <h1>Oops</h1>
            <p>The page you are looking for does not exist.</p>
            <h4>Try to catch the emoji instead.</h4>
            <br />
            <CatchTheEmoji />
        </div>

    );

export default NotFound;

import React, { useEffect  } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';

const BlogPost7 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">My Time at the Cyber Security Challenge Belgium</h1>
            <p className="intro">This year, I got to be part of the Cyber Security Challenge Belgium (CSCBE), which is this big competition where you solve cybersecurity puzzles. It was organized by NVISO, and a ton of students from all over Belgium joined in.</p>

            <h2>What's CSCBE All About?</h2>
            <p>Basically, CSCBE is this competition where you and your team try to solve as many cybersecurity problems as you can in a certain amount of time. We tackled a variety of problems across several subjects that we've encountered in our studies, including reverse engineering, modifying APKs, identifying anomalies in Wireshark PCAP files, Cryptography, Forensics and Web challenges. < br/>
                Me and some friends from school decided to team up for these challenges.</p>

            <h3>Day 1: Off to a Good Start</h3>
            <p>The first day was pretty awesome. We solved a bunch of challenges. It felt great to use what we've learned in class in a real competition. By the end of the day, we were doing really well and were excited for the next day.</p>

            <h3>Day 2: A Bit Tougher</h3>
            <p>The second day threw some harder problems at us. Some of the stuff we hadn't even seen before! But we didn't give up. We searched the internet, worked together, and managed to solve more challenges. Sadly we did not gather enough points to get info the finals</p>

            <h2>Looking Back</h2>
            <p>Participating in CSCBE was challenging, but I learned a lot and had a great time with my friends. </p>

            <p>If you're thinking about trying something like this, definitely go for it. And if you want to know more about the challenges or maybe join in next year, check out the CSCBE's GitHub or Discord. It's a great experience!</p>

            <div className="video-container">
                <img src= "/img/blog/cscbe.png" alt="cscbe" />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/404-game">
                    <ArrowBack className="icon-left" />
                    404: The Game
                </a>
                <a className="a-blog" href="/blog/cybersecurity-lecture">
                    Cybersecurity Lecture
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost7;

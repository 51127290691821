import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  // Initialize themeName from localStorage, falling back to system preference
  const [themeName, setThemeName] = useState(
      localStorage.getItem('themeName') ||
      (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')
  );

  useEffect(() => {
    // Update localStorage whenever themeName changes
    localStorage.setItem('themeName', themeName);
  }, [themeName]);

  useEffect(() => {
    // Update theme based on system preference changes
    const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleMediaQueryChange = (e) => {
      setThemeName(e.matches ? 'dark' : 'light');
    };

    darkMediaQuery.addEventListener('change', handleMediaQueryChange);

    return () => {
      darkMediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  const toggleTheme = () => {
    setThemeName(themeName === 'dark' ? 'light' : 'dark');
  };

  return (
      <ThemeContext.Provider value={{ themeName, toggleTheme }}>
        {children}
      </ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ThemeProvider, ThemeContext };

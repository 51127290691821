import React from 'react';
import { Link } from 'react-router-dom';
import './BlogContainer.css';

const BlogContainer = ({ blog }) => (
    <div className='blog-post'>
        <Link to={blog.source} className='link link--title'>
            <h3>{blog.title}</h3>
        </Link>

        {blog.image && (
            <Link to={blog.source}>
                <img src={blog.image} alt={blog.title} className='blog-post__image' />
            </Link>
        )}

        <p className='blog-post__description'>{blog.description}</p>

        <div className='blog-post__links'>
            <Link to={blog.source} className='link link--source'>
                Read More
            </Link>
        </div>
    </div>
);

export default BlogContainer;

import React, { useEffect } from 'react';
import './BlogPost.css';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';

const BlogPost8 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="container">
            {/* eslint-disable react/no-unescaped-entities */}
            <h1 className="blog-title">Our Lecture to High School Students</h1>
            <p className="intro">Ali, Arman and myself recently had the chance to talk to high school students about something we're super passionate about: <br />
                Cybersecurity. It's all about keeping our online lives safe, and we wanted to share why it's so important.</p>

            <h2>Starting Off with Ali</h2>
            <p>Ali got things rolling by explaining how cybersecurity is everywhere in our daily lives and why it's something we should all care about. It's not just for experts; it's for everyone who uses the internet.</p>

            <h2>Getting into the Details with Arman</h2>
            <p>Arman took over to dive deeper into what makes our online accounts secure. He talked about things like 2FA/MFA, the difference between passphrases and passwords, and why we should all start thinking about using passkeys. He also mentioned how longer passwords are better and why password managers are a game changer in keeping our digital lives secure.</p>

            <h2>Exploring the Darker Side</h2>
            <p>We showcased the scarier parts of the internet, like the dark web, and talked about how places online can put our info at risk. We also introduced the students to HaveIBeenPwned, a website that can tell us if our information has been part of a data breach. Plus, we explained how using something like Bitlocker to encrypt our data can add another layer of security.</p>

            <h2>Seeing It in Action</h2>
            <p>One of the coolest parts was when we showed a live demo of how threat actors can break into accounts, by looking up leaked credentials and using brute force attacks. It was a real eye-opener for the students to see how quickly someone could crack a simple password.</p>

            <h2>Wrapping Up</h2>
            <p>Ali wrapped things up by going over everything we talked about. He stressed the importance of taking steps to secure our online presence.</p>

            <p>Sharing what we know about cybersecurity with these students was an awesome experience. If we managed to make even a few of them more aware of their digital security, then we’d call it a win.</p>


            <div className="video-container">
                <img src="" alt="" />
            </div>

            <div className="blog-nav">
                <a className="a-blog" href="/blog/cscbe-ctf">
                    <ArrowBack className="icon-left" />
                    CSCBE CTF
                </a>
                <a className="a-blog" href="/blog">
                    Blog Overview
                    <ArrowForwardIcon className="icon-right" />
                </a>
            </div>
        </div>
    );
};

export default BlogPost8;

import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeContext } from './contexts/theme';
import Blog from './components/Blog/Blog';
import BlogPost1 from './components/BlogPost/BlogPost1';
import BlogPost2 from './components/BlogPost/BlogPost2';
import BlogPost3 from './components/BlogPost/BlogPost3';
import BlogPost4 from './components/BlogPost/BlogPost4';
import BlogPost5 from './components/BlogPost/BlogPost5';
import BlogPost6 from './components/BlogPost/BlogPost6';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Skills from './components/Skills/Skills';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import NotFound from './components/NotFound/NotFound';
import './App.css';
import BlogPost7 from "./components/BlogPost/BlogPost7";
import BlogPost8 from "./components/BlogPost/BlogPost8";

const App = () => {
    const { themeName } = useContext(ThemeContext);

    return (
        <Router>
            {}
            <div id='top' className={`${themeName} app`}>
                <Header />
                <Routes>
                    <Route path='/' element={<main>
                        <About />
                        <Projects />
                        <Skills />
                        <Contact />
                    </main>} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/campus-cloud-cafe' element={<BlogPost1 />} />
                    <Route path='/blog/webinar-zero-trust' element={<BlogPost2 />} />
                    <Route path='/blog/ConXioN-study-visit' element={<BlogPost3 />} />
                    <Route path='/blog/advent-of-cyber-2023' element={<BlogPost4 />} />
                    <Route path='/blog/the-making-of-this-website' element={<BlogPost5 />} />
                    <Route path='/blog/404-game' element={<BlogPost6 />} />
                    <Route path='/blog/cscbe-ctf' element={<BlogPost7 />} />
                    <Route path='/blog/cybersecurity-lecture' element={<BlogPost8 />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>

                <ScrollToTop />
                <Footer />
            </div>
        </Router>
    );
};

export default App;
